html,
body,
#root {
  margin: 0;
  height: 100%;
  font-family: Roboto, sans-serif;
}

/*
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-Italic.ttf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

/*
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-MediumItalic.ttf");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Roboto-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}
*/
